import styled from 'styled-components';

export const TransactionDetailsContainer = styled.div`
	background: #edf2f7;
	border-radius: 0.5rem;
	padding: 1rem;
	gap: 0.7rem;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	font-size: 0.9rem;

	p {
		margin: 0;
	}

	span {
		padding: 0.2rem 0.5rem;
		background: #fff;
		border-radius: 0.5rem;
		margin-left: 0.5rem;
	}
`;
